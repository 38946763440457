import { Instance } from '@lumapps/instance/types';
import { FlagProps } from '@lumapps/lumx/react';
import { Media } from '@lumapps/medias/types';
import { TranslatedMetadataListV2 } from '@lumapps/metadata/types';
import { Route } from '@lumapps/router';
import { SubscriptionType } from '@lumapps/subscriptions/types';
import { GLOBAL, TranslateObject } from '@lumapps/translations';
import { ApiProfile, User } from '@lumapps/user/types';
import { RecursivePartial } from '@lumapps/utils/types/RecursivePartial';

export enum ContentHeaderDisplayType {
    overlay = 'overlay',
    gradient = 'gradient',
    textBackground = 'text-background',
}

export enum LegacyContentStatus {
    live = 'LIVE',
    archive = 'ARCHIVE',
    draft = 'DRAFT',
    toValidate = 'TO_VALIDATE',
    unarchive = 'UNARCHIVE',
    refused = 'REFUSED',
}

export const TranslationsAndColorForContentStatus: Record<
    LegacyContentStatus,
    { key: string; color: FlagProps['color'] }
> = {
    LIVE: {
        key: GLOBAL.PUBLISHED,
        color: 'green',
    },
    ARCHIVE: {
        key: GLOBAL.ARCHIVED,
        color: 'grey',
    },
    DRAFT: {
        key: GLOBAL.DRAFT,
        color: 'yellow',
    },
    TO_VALIDATE: {
        key: GLOBAL.DRAFT,
        color: 'yellow',
    },
    UNARCHIVE: {
        key: GLOBAL.DRAFT,
        color: 'yellow',
    },
    REFUSED: {
        key: GLOBAL.REFUSED,
        color: 'yellow',
    },
};

export enum ContentType {
    community = 'community',
    content = 'content',
}

/** Type used to reference a link to a content. */
export interface ContentLinkRef {
    /** Content id. */
    id?: string;
    /** Content slug. */
    slug?: string;
    /** Content title */
    title?: string;
    /** Content type. */
    type?: string;
    /** Content external key id */
    externalKey?: string;
    /** Anchor to scroll to on the content page. */
    anchor?: Route['anchor'];
    /** Content page query params. */
    query?: Route['query'];
    /** Whether this content the home page or not. */
    isHomePage?: boolean;
    /** Whether this content is known to be compatible with layout v2 or not. */
    isV2Compatible?: boolean;
    /** Content external link */
    link?: string;
    /**
     * Content instance.
     * Used to generate links cross instance.
     */
    instance?: Partial<Pick<Instance, 'id' | 'slug'>>;
    /**
     * Whether to use the parent path included in the slug or use only the last part of the slug.
     * Useful for navigation items.
     */
    keepParentPath?: boolean;
    /**
     * Whether the content link ref has stale data or not (slug could have changed, instance slug could have changed, etc.)
     */
    hasStaleData?: boolean;
}

/** Information about the author of the content. */
export interface AuthorDetails {
    /** The uid of the author. */
    uid?: string;
    /** Use this as a fallback, since it is the old id field. */
    id?: string;
    /** The fullname of the author. */
    fullName?: string;
    /** The author's customer id. */
    customer?: string;
    /** Additional information on the user */
    apiProfile?: ApiProfile;
    /** user email */
    email?: string;
    /** user last name */
    lastName?: string;
    /** user first name */
    firstName?: string;
    /** user profile picture url set by the backend */
    profilePictureUrl?: string;
}

/** @see https://lumapps-api.stoplight.io/docs/lumapps-public-api/d78acf1cf0543-content */
export interface Content {
    id: string;
    isDesignerMode?: boolean;
    isFeatured?: boolean;
    editors?: string[];
    type: string;
    instance?: string;
    analyticsViewSum?: string;
    startDate?: string;
    endDate?: string;
    instanceDetails?: Pick<Instance, 'slug' | 'name' | 'id'>;
    adminKeys?: string[];
    properties?: Record<string, any>;
    slug?: Record<string, any>;
    link?: Record<string, any>;
    status?: LegacyContentStatus;
    title?: Record<string, any>;
    canonicalUrl?: Record<string, any>;
    header?: string;
    isHomepage?: boolean;
    view?: string;
    excerpt?: TranslateObject;
    headerDetails?: {
        uid?: string;
        name?: string;
        author?: string;
        instance?: string;
        updatedBy?: string;
        properties?: {
            random?: boolean;
            autoplay?: boolean;
            layoutPosition?: number;
            media?: Media[];
            wrapperHeight?: number;
            interval?: number;
            style?: ContentHeaderDisplayType;
        };
        createdAt?: string;
        media?: string[];
        customer?: string;
        public?: boolean;
        heritable?: boolean;
        updatedAt?: string;
        height?: number;
        id?: string;
    };
    customContentType?: string;
    author?: string;
    authorId?: string;
    updatedAt?: string;
    createdAt?: string;
    lastPublishedAt?: string;
    template?: {
        uid?: string;
        customContentType?: string;
        /** Content template has been fully migrated to layout V2. */
        isV2Compatible?: boolean;
        /** Simple template mode */
        basicMode?: boolean;
    };
    canContribute?: boolean;
    relevantComment?: string;
    canEdit?: boolean;
    feedKeys?: string[];
    featuredFeedKeys?: string[];
    /** The url to the content thumbnail. Only used as a fallback if mediaThumbnail is missing */
    thumbnail?: string;
    mediaThumbnail?: RecursivePartial<Media>;
    notificationsEnabled?: boolean;
    /** The list of feeds IDs to notify. */
    notifyFeedKeys?: string[];
    /** Whether the content is loading or fields are being fetched. */
    isLoading?: boolean;
    /** Number of comments. */
    comments?: number;
    /** Whether the content has been liked by the connected user. */
    liked?: boolean;
    /** Number of likes. */
    likes?: number;
    /** List of user having liked the content. */
    likesList?: {
        items: User[];
        isLoaded: boolean;
    };
    /** Whether the content is followed by the connected user. */
    following?: boolean;
    /** Whether the content is followed by the connected user. */
    subscription?: SubscriptionType;
    /** Information concerning the author of the content. */
    authorDetails?: AuthorDetails;
    /** When a content is written on behalf a user, the writer details is filled with users' informations. */
    writerDetails?: AuthorDetails;
    /** Information concerning the last user that updated the content. */
    updatedByDetails?: AuthorDetails;
    /** Publication date of the content. */
    publicationDate?: string;
    /** List of tags IDs linked to the content. */
    customContentTypeTags?: string[];
    /** Information concerning the tags of the content. */
    customContentTypeDetails?: {
        tags: any[];
        icon?: string;
        id: string;
        endDateDelta?: number;
        isEndDateMandatory?: boolean;
        isWorkflowEnabled?: boolean;
        name?: TranslateObject;
    };
    lastRevision?: {
        createdAt: string;
        updatedAt: string;
        revisionKey: string;
        status: LegacyContentStatus;
        title: TranslateObject;
        version: string;
    };
    /** List of content's metadata ids */
    metadata?: string[];
    /** Information concerning the metadata of the content. */
    metadataDetails?: TranslatedMetadataListV2;
    /** Whether the piece of content has a comment widget */
    hasCommentWidget?: boolean;
    /** The ids of the tags associated to the content */
    tags?: any[];
    /** The details of the tags associated to the content */
    tagsDetails?: any[];
    /** optional external key that returns directory id */
    externalKey?: string;
}
