import React from 'react';

import { Redirect, RouteProps } from 'react-router-dom';

import { articleViewRoute } from '@lumapps/articles/routes';
import { communityEventsRoute } from '@lumapps/community-events/routes';
import { eventViewRoute } from '@lumapps/events/routes';
import {
    endUserJourneyItemViewRoute,
    endUserJourneysAsStakeholderRoute,
    endUserJourneysListRoute,
    endUserJourneysViewRoute,
} from '@lumapps/journeys-front-office/routes';
import { learningPathPageRoute } from '@lumapps/learning-learning-path/routes';
import { trainingCoursePageRoute } from '@lumapps/learning-training-course/routes';
import { microAppByIdFrontOfficeRoute } from '@lumapps/micro-apps/routes';
import { playGalleryRoute } from '@lumapps/play-gallery/routes';
import { playlistRoute } from '@lumapps/play-playlists/routes';
import { playLibraryRoute } from '@lumapps/play-video-library/routes';
import { playVideoRoute } from '@lumapps/play-video/routes';
import { reviewPageRoute } from '@lumapps/reports/routes';
import { notFoundRoute, errorRoute, Route } from '@lumapps/router';
import { URL_PREFIX } from '@lumapps/router/constants';
import { searchRoute as searchRouteV2 } from '@lumapps/search-page-v2/routes';
import { searchRoute, mobileSearchRoute } from '@lumapps/search/routes';
import { spaceViewRoute } from '@lumapps/spaces/routes';
import { feedRoute } from '@lumapps/user-feed/routes';
import { historyRoute } from '@lumapps/user-history/routes';
import { userProfileRoute } from '@lumapps/user-profile/routes';
import { savedItemsRoute } from '@lumapps/user-saved-items/routes';
import { userSettingsRoute } from '@lumapps/user-settings/routes';
import { subscriptionsRoute } from '@lumapps/user-subscriptions/routes';

/**
 * In this routes, the slideshow will not be displayed.
 */
export const NO_SLIDESHOW_PAGES: Route['path'][] = [
    articleViewRoute.path,
    communityEventsRoute.path,
    eventViewRoute.path,
    playLibraryRoute.path,
    playVideoRoute.path,
    playGalleryRoute.path,
    playlistRoute.path,
    userProfileRoute.path,
    reviewPageRoute.path,
    searchRoute.path,
    searchRouteV2.path,
    trainingCoursePageRoute.path,
    mobileSearchRoute.path,
    savedItemsRoute.path,
    feedRoute.path,
    subscriptionsRoute.path,
    historyRoute.path,
    endUserJourneyItemViewRoute.path,
    endUserJourneysViewRoute.path,
    endUserJourneysListRoute.path,
    endUserJourneysAsStakeholderRoute.path,
    spaceViewRoute.path,
    userSettingsRoute.path,
    microAppByIdFrontOfficeRoute.path,
    notFoundRoute.path,
    errorRoute.path,
    learningPathPageRoute.path,
];

/**
 * In these routes, the page will have an alternative background color.
 */
export const ALTERNATIVE_BACKGROUND_COLOR_PAGES: Route['path'][] = [
    playVideoRoute.path,
    playGalleryRoute.path,
    playlistRoute.path,
    searchRoute.path,
    searchRouteV2.path,
    mobileSearchRoute.path,
    endUserJourneysViewRoute.path,
    endUserJourneysListRoute.path,
    endUserJourneysAsStakeholderRoute.path,
];

export const ALTERNATIVE_BACKGROUND_CLASS = 'alternative-bkg';

interface RedirectProps extends RouteProps {
    key: string;
}

/**
 * These redirects are in place in order to allow migrations, change URLs due to customers using
 * the incorrect links, etc. They will be rendered on our Pages/index.tsx component. Before adding
 * something here, please make sure that you can avoid this and do either a redirect on the backend,
 * or no redirect at all, and send customers to a 404 page.
 */
export const REDIRECTS: RedirectProps[] = [
    /**
     * To add a redirect, create the following object
     * 
     *
        {
            key: <unique key>,
            path: <old relative path>,
            render: (props) => {
                return <Redirect to={<new url>} />; // Redirect from @lumapps/router
            },
        },
     */
    {
        // Add /ls to all journeys urls without loosing any part of the url
        key: 'old-journeys',
        path: '/journeys',
        render: ({ location }) => <Redirect to={`/${URL_PREFIX}${location.pathname}${location.search}`} />,
    },
];
