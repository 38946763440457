/* eslint-disable import/order */
/* eslint-disable import/first */
/* istanbul ignore file */
import React from 'react';
import { Route } from '@lumapps/router';

const PagesConfig: Record<Route['path'], React.LazyExoticComponent<any>> = {};

/**
 * IMPORTANT!
 *
 * Here you will find the different pages that are currently in our application. You will notice.
 * that there is a specific convention in place. In essence:
 * - Pages are grouped by functionality. You will notice the comments Group: <id> and End: <id>.
 * - These comments NEED TO BE PRESENT.
 * - Please use those comments when defining a new group
 * - If a page belongs to an already existing group, please add it to the group and avoid creating a new one.
 */

/** Group: search */
import { searchRoute, mobileSearchRoute } from '@lumapps/search/routes';
import { searchRoute as searchRouteV2 } from '@lumapps/search-page-v2/routes';

const SearchPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'search-page' */
            '@lumapps/search-page/components/Page'
        ),
);

const SearchPageV2 = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'search-page-v2' */
            '@lumapps/search-page-v2/components/SearchPage'
        ),
);

PagesConfig[searchRoute.path] = SearchPage;
PagesConfig[searchRouteV2.path] = SearchPageV2;
PagesConfig[mobileSearchRoute.path] = SearchPage;
/** End: search */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: articles */
import { articleViewRoute } from '@lumapps/articles/routes';

const ArticleViewPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'article-view-page' */
            '@lumapps/articles/components/ArticleViewPage'
        ),
);

PagesConfig[articleViewRoute.path] = ArticleViewPage;
/** End: articles */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: communities */
import { communityEventsRoute } from '@lumapps/community-events/routes';

const CommunityEventsPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'community-events-page' */
            '@lumapps/community-events/components/CommunityEvents'
        ),
);

PagesConfig[communityEventsRoute.path] = CommunityEventsPage;
/** End: communities */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */
/** Group: space */
import { spaceViewRoute } from '@lumapps/spaces/routes';

const SpaceViewsPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'space-views-page' */
            '@lumapps/spaces/components/SpaceViewPage'
        ),
);

PagesConfig[spaceViewRoute.path] = SpaceViewsPage;
/** End: space */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: events */
import { eventViewRoute } from '@lumapps/events/routes';

const EventViewPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'event-view-page' */
            '@lumapps/events/components/EventViewPage'
        ),
);

PagesConfig[eventViewRoute.path] = EventViewPage;
/** End: events */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: reports */
import { reviewPageRoute } from '@lumapps/reports/routes';

const ReviewPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'review-page' */
            '@lumapps/reports/components/ReviewPage'
        ),
);

PagesConfig[reviewPageRoute.path] = ReviewPage;
/** End: reports */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: play */
import { playLibraryRoute } from '@lumapps/play-video-library/routes';
import { playVideoRoute } from '@lumapps/play-video/routes';
import { playGalleryRoute } from '@lumapps/play-gallery/routes';
import { playlistRoute } from '@lumapps/play-playlists/routes';

const PlayLibraryPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'play-library-page' */
            '@lumapps/play-video-library/components/Play'
        ),
);

const PlayVideoPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'play-video-page' */
            '@lumapps/play-video/components/PageVideo'
        ),
);

const PlayGalleryPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'play-gallery-page' */
            '@lumapps/play-gallery/pages/Gallery'
        ),
);

const PlayPlaylistPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'play-playlist-page' */
            '@lumapps/play-playlists/pages/PlaylistPage'
        ),
);

PagesConfig[playLibraryRoute.path] = PlayLibraryPage;
PagesConfig[playVideoRoute.path] = PlayVideoPage;
PagesConfig[playGalleryRoute.path] = PlayGalleryPage;
PagesConfig[playlistRoute.path] = PlayPlaylistPage;
/** End: play */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: user */
import { savedItemsRoute } from '@lumapps/user-saved-items/routes';
import { feedRoute } from '@lumapps/user-feed/routes';
import { userProfileRoute } from '@lumapps/user-profile/routes';
import { subscriptionsRoute } from '@lumapps/user-subscriptions/routes';
import { historyRoute } from '@lumapps/user-history/routes';
import { userSettingsRoute } from '@lumapps/user-settings/routes';

const UserProfilePage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'user-profile-page' */
            '@lumapps/user-profile/components/UserProfilePage'
        ),
);

const SavedItemsPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'user-saved-items-page' */
            '@lumapps/user-saved-items/components/SavedItemsPage'
        ),
);

const FeedPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'feed-page' */
            '@lumapps/user-feed/components/FeedPage'
        ),
);

const SubscriptionsPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'user-subscriptions-page' */
            '@lumapps/user-subscriptions/components/SubscriptionsPage'
        ),
);

const HistoryPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'reading-history-page' */
            '@lumapps/user-history/components/HistoryPage'
        ),
);

const UserSettingsPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'user-settings-page' */
            '@lumapps/user-settings/components/UserSettingsPage'
        ),
);

PagesConfig[userProfileRoute.path] = UserProfilePage;
PagesConfig[savedItemsRoute.path] = SavedItemsPage;
PagesConfig[feedRoute.path] = FeedPage;
PagesConfig[subscriptionsRoute.path] = SubscriptionsPage;
PagesConfig[historyRoute.path] = HistoryPage;
PagesConfig[userSettingsRoute.path] = UserSettingsPage;
/** End: user */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: journeys */
import { endUserJourneysBaseRoute } from '@lumapps/journeys-front-office/routes';

const EndUserJourneys = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'end-user-journeys' */
            '@lumapps/journeys-front-office/components/EndUserJourneys'
        ),
);

PagesConfig[endUserJourneysBaseRoute.path] = EndUserJourneys;
/** End: journeys */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: learning */
import { trainingPageRoute } from '@lumapps/learning-trainings/routes';
import { learningRoute, learningManagerRoute } from '@lumapps/learning-page/routes';
import { trainingCoursePageRoute } from '@lumapps/learning-training-course/routes';
import { learningPathPageRoute } from '@lumapps/learning-learning-path/routes';

const trainingPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'training-by-id-page' */
            '@lumapps/learning-trainings/components/TrainingPage'
        ),
);

const trainingCoursePage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'training-course-page-by-id' */
            '@lumapps/learning-training-course/components/TrainingCoursePage'
        ),
);

const learningPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'learning-main-page' */
            '@lumapps/learning-page/components/LearningPage'
        ),
);

const learningManagerPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'learning-main-page-manager' */
            '@lumapps/learning-page/components/LearningManagerPage'
        ),
);

const learningPathPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'learning-path-pages' */
            '@lumapps/learning-learning-path/components/LearningPathPage'
        ),
);

PagesConfig[trainingPageRoute.path] = trainingPage;
PagesConfig[trainingCoursePageRoute.path] = trainingCoursePage;
PagesConfig[learningRoute.path] = learningPage;
PagesConfig[learningManagerRoute.path] = learningManagerPage;
PagesConfig[learningPathPageRoute.path] = learningPathPage;

/** End: learning */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: digital-assistant */
import { microAppByIdFrontOfficeRoute } from '@lumapps/micro-apps/routes';

const microAppPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'micro-app-by-id-page' */
            '@lumapps/micro-apps/components/MicroAppPage'
        ),
);

PagesConfig[microAppByIdFrontOfficeRoute.path] = microAppPage;
/** End: digital-assistant */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

/** Group: content */
import { contentPreviewRoute } from '@lumapps/content-preview/routes';

const contentPreviewPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'content-preview-page' */
            '@lumapps/content-preview/components/ContentPreviewPage'
        ),
);

PagesConfig[contentPreviewRoute.path] = contentPreviewPage;
/** End: content */

/** Group: user-directory */
import { UserDirectoryRoute } from '@lumapps/user-directory-front-office/routes';

const userDirectoryPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'user-directory-page' */
            '@lumapps/user-directory-front-office/components/UserDirectoryPage'
        ),
);

PagesConfig[UserDirectoryRoute.path] = userDirectoryPage;

/** End: user-directory */

/**
 * ==========================================================================================================
 * ==========================================================================================================
 * ==========================================================================================================
 */

export { PagesConfig };
