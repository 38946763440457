import { isDateInTheFuture } from '@lumapps/utils/date/isDateInTheFuture';
import { isDateInThePast } from '@lumapps/utils/date/isDateInThePast';

import { CONTENT_PUBLICATION_STATUS, type ContentPublicationStatus } from '../constants';
import { LegacyContentStatus, type Content } from '../types';

/** Get the content publication status thanks to the legacy status and his dates
 * CF: [backend code](https://github.com/lumapps/core/blob/master/core/monolite/content/use_cases/property_computations.py#L31)
 */
export const getContentPublicationStatus = ({
    legacyStatus,
    startDate,
    endDate,
    updatedAt,
    lastRevision,
}: {
    legacyStatus?: LegacyContentStatus;
    startDate?: string;
    endDate?: string;
    updatedAt?: string;
    lastRevision?: Content['lastRevision'];
}) => {
    let publicationStatus: ContentPublicationStatus = CONTENT_PUBLICATION_STATUS.DRAFT;

    const hasPendingDraft = updatedAt !== lastRevision?.updatedAt;
    const isAwaitingValidation = legacyStatus === LegacyContentStatus.toValidate;
    const isExpiringSoon =
        legacyStatus === LegacyContentStatus.live &&
        !!endDate &&
        new Date(endDate) < new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
    const isRevisionRequested = legacyStatus === LegacyContentStatus.refused;

    if (legacyStatus === LegacyContentStatus.archive) {
        publicationStatus = CONTENT_PUBLICATION_STATUS.ARCHIVE;
    } else if (legacyStatus === LegacyContentStatus.live) {
        if (endDate && isDateInThePast(endDate)) {
            publicationStatus = CONTENT_PUBLICATION_STATUS.EXPIRED;
        } else if (startDate && isDateInTheFuture(startDate)) {
            publicationStatus = CONTENT_PUBLICATION_STATUS.SCHEDULED;
        } else {
            publicationStatus = CONTENT_PUBLICATION_STATUS.PUBLISHED;
        }
    } else if (legacyStatus === LegacyContentStatus.draft || isAwaitingValidation || isRevisionRequested) {
        if (hasPendingDraft) {
            if (startDate && isDateInTheFuture(startDate)) {
                publicationStatus = CONTENT_PUBLICATION_STATUS.SCHEDULED;
            } else if (endDate && isDateInThePast(endDate)) {
                publicationStatus = CONTENT_PUBLICATION_STATUS.EXPIRED;
            } else {
                publicationStatus = CONTENT_PUBLICATION_STATUS.PUBLISHED;
            }
        } else {
            publicationStatus = CONTENT_PUBLICATION_STATUS.DRAFT;
        }
    }

    return {
        publicationStatus,
        hasPendingDraft,
        isAwaitingValidation,
        isExpiringSoon,
        isRevisionRequested,
    };
};
