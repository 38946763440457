import { createPageRoute, Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

/** Get the learning path route path. */
export const getLearningPathPath = () => {
    return `/${URL_PREFIX}/learning-paths/:id`;
};

export const learningPathPageRoute: Route = createPageRoute({
    slug: 'learning-paths/:id',
    appId: AppId.frontOffice,
});

export const webviewLearningPathPageRoute: Route = createPageRoute({
    slug: 'webview/learnig-paths/:id',
    appId: AppId.webview,
});

/** Get the learning path route. */
export const getLearningPathRoute = (id?: string): Route => {
    return {
        ...learningPathPageRoute,
        params: {
            id,
        },
        path: getLearningPathPath(),
    };
};
